@import 'src/uikit/stylesheets/styles.scss';

.limits-table-cell {
  vertical-align: middle;
  @extend %body;
}

.limits-table-cell-action {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .limits-table-cell-action-enabled {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .dropdown-choice {
      padding: 0 !important;
      width: fit-content;
    }
  }

}

.limits-loader-placeholder {
  border-radius: 4px;
}
