@import 'src/uikit/stylesheets/styles';

.limitValue-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;

  .limitValue-wrapper-hold-input {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-neutral;
    border-radius: 6px;
    .table-cell-input input {
      border: none !important;
    }
    .limitValue-wrapper-hold-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light;
      padding: 8px
    }
  }

  .limitValue-wrapper-reject-input {
    display: flex;
    flex-direction: row;
    border: 1px solid $light-neutral;
    border-radius: 6px;
    .table-cell-input input {
      border: none !important;
    }
    .limitValue-wrapper-reject-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $light;
      padding: 8px
    }
  }
}