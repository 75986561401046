@import 'src/uikit/stylesheets/styles.scss';

.tariffs-table-head {
  vertical-align: middle;
  @extend %body;
}

.tariffs-table-head-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

//.tariffs-table-head:last-child {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  justify-content: flex-end;
//  gap: 10px;
//}