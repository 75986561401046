@import 'src/styles';

.form-newLimitType {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 32px 0 0 0;

  .form-newLimitType-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }


  .form-newLimitType-status {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    .form-newLimitType-status-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      width: 100%;

      .dropdown-choice {
        padding: 0 !important;
        width: fit-content;
      }
    }
  }

  .form-newLimitType-hold-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    margin-bottom: 24px;

    .form-newLimitType-hold-wrapper-input {
      display: flex;
      flex-direction: row;
      border: 1px solid $light-neutral;
      border-radius: 6px;
      .table-cell-input input {
        border: none !important;
      }
      .form-newLimitType-hold-wrapper-currency {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $light;
        padding: 8px
      }
    }
  }
}