@import 'src/styles';


.tariffs-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;

  .tariffs-container-actions {
    display: flex;
    flex-direction: row;
    gap: 8px;


    .tariffs-container-actions-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px !important;
      height: fit-content !important;
      padding: 6px 12px;
      box-sizing: border-box;
    }
  }
}

.tariffs-tab-loader-placeholder {
  border-radius: 4px;
}

.tariffs-tab-loader {
  height: calc(100vh - 209px) !important;
}

