@import 'src/styles';


.limitsDetails-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}
.limits-table {
  width: 100%;
}

.table-row-highlighted {
  font-weight: bold;
  background-color: $light;
}

.limits-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px !important;
  height: fit-content !important;
  padding: 6px 12px;
  box-sizing: border-box;
}

.limits-details-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
